/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"

import { useStaticQuery, graphql, Link } from "gatsby"

function Header() {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "images/Logo_transparent.png" }) {
        childImageSharp {
          fluid(maxWidth: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      allMarkdownRemark(limit: 6) {
        group(field: frontmatter___tags) {
          fieldValue
          totalCount
        }
      }
    }
  `)
  return (
    <header>
      <nav className="headernav">
        <div className="btn-wrapper">
          <button
            id="menuBtn"
            className="hamburger block sm:hidden focus:outline-none"
            type="button"
            onClick={navToggle}
          >
            <span className="hamburger__top-bun"></span>
            <span className="hamburger__middle-bun"></span>
            <span className="hamburger__bottom-bun"></span>
          </button>
        </div>
        <div
          id="menu"
          className="container mx-auto sm:w-auto self-end sm:self-center sm:flex flex-col sm:flex-row items-center h-full py-1 pb-4 px-0 sm:py-0 sm:pb-0 closed"
        >
          <a href="https://www.instagram.com/thejourneyofdennis/">Instagram</a>

          <a href="/wer-bin-ich">Über mich</a>

          <a href="/contact">Kontakt</a>
        </div>
      </nav>
      <div className="header-logo">
        <a href="/" className="logo-wrapper">
          <p>The</p>
          <p>Journey</p>
          <p>of</p>
          <p>Dennis</p>
        </a>
      </div>
      <p className="text-center font-serif font-light text-sm italic">
        Selbstentwicklung, Familie und mehr
      </p>
      <nav className="mb-6 tagheader hidden">
        <ul className="flex flex-row justify-center">
          {/* {data.allMarkdownRemark.group.map(( tag , index) => {
          if (index < 3)
          return (
          <li key={tag.fieldValue}  className="mx-3">
            <Link to={`/tags/${kebabCase(tag.fieldValue)}/`}>
              {tag.fieldValue}
            </Link>
          </li>
          )
          })} */}
          <li className="mx-3">
            <Link to={`/tags/familie/`}>Familie</Link>
          </li>
          <li className="mx-3">
            <Link to={`/tags/personliches/`}>Persönliches</Link>
          </li>
          <li className="mx-3">
            <Link to={`/tags/self-improvement/`}>Selbstentwicklung</Link>
          </li>
        </ul>
      </nav>
    </header>
  )
}

function toggleDarkMode() {
  var element = document.getElementsByTagName("body")[0]
  element.classList.toggle("dark-mode")
}
function navToggle() {
  var btn = document.getElementById("menuBtn")
  var nav = document.getElementById("menu")

  btn.classList.toggle("open")
  nav.classList.toggle("open")
  nav.classList.toggle("closed")
}
export default Header
