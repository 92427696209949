import React from "react"
import Header from "./header"
import CookieConsent, { Cookies } from "react-cookie-consent";

class Layout extends React.Component {
  render() {
    const { location, title, children } = this.props

    return (
<>
      <Header />
      <div className="page-main">
        <main className="container mx-auto">{children}</main>
        <footer className="container mx-auto mt-8 flex flex-row flex-wrap">
          © {new Date().getFullYear()}, Dennis Wiemann
          <p className="mx-3">
            <a href="/privacy">Datenschutzerklärung</a>
          </p>
          <p>
            <a href="/impressum">Impressum</a>
          </p>
        </footer>
        <CookieConsent
          location="bottom"
          buttonText="Zustimmen"
          declineButtonText="Ablehnen"
          cookieName="gatsby-gdpr-google-analytics"
          style={{ background: "#ffffff", border:'1px solid black', color:'black', minHeight:'30%'}}
          buttonStyle={{marginTop:0, color: "#000000", fontSize: "13px", background:'#e2e2e2', border:' 1px solid #000000' }}
          enableDeclineButton
          declineButtonStyle={{ marginTop:0, color: "#000000", fontSize: "13px", background:'#ffffff', border:' 1px solid #000000' }}
          flipButtons
        >
          <p  className="text-sm lg:text-base pb-2">
          Als Blogger möchte ich wissen ob meine Blog-Beiträge auch gelesen werden. Dabei ist es für mich persönlich nicht interessant von wem genau, sondern meine Besucher auf meinem Blog gekommen sind und haben Sie nur versehentlich auf den Link geklickt oder verbringen sie wirklich Zeit auf meinem Blog. Dies hilft mir ungemein an meinem Ziel regelmäßig neue Beiträge zu verfassen, da ich weiß: Ihr seid da.</p>
          <p className="text-sm lg:text-base">
          Diese Webseite verwendet Cookies um das Benutzererlebnis zu verbessern. Um mehr über die Verarbeitung der Daten zu erfahren klicken Sie auf{" "}
          <span style={{ fontSize: "13px", color:"#333333" }}>
            <a href="/privacy">Datenschutzerklärung</a>
          </span>
          </p>
        </CookieConsent>
      </div>
      </>
    )
  }
}

export default Layout
